import BigNumber from 'bignumber.js'
import {useCallback, useEffect, useState} from "react"
import {use721Contract} from "./useContract";
import {useActiveWeb3React} from "./web3";

export const useMintNFT = () => {

    const contract = use721Contract();
    const { account } = useActiveWeb3React();

    const [isWhiteListed, setIsWhiteListed] = useState(false)
    const [maxlimit, setmaxlimit] = useState([]);

    useEffect(() => {
        const walletOfOwner = async (_account1) => {
            try {
                const response = await contract.walletOfOwner(_account1);
                setmaxlimit(response);
            } catch (error) {
                console.error("Error fetching walletOfOwner:", error);
            }
        };

        // const getWhiteListed = async (_account: string) => setIsWhiteListed(await contract.isWhitelisted(_account))

        if (account) {
            walletOfOwner(account);
        }
    }, [account, contract]);

    const mint = useCallback(async () => {
        if (account) {
            try {
                const amount_wei = new BigNumber(600).shiftedBy(18).toString();
                const tx = await contract.mint(1, { value: amount_wei, gasLimit: 5000000 });
                const receipt = await tx.wait();
                // console.log("Receipt:", receipt);
            } catch (error) {
                // console.error("Error minting NFT:", error);
            }
        }
    }, [account, contract]);

    // [account, contract, isWhiteListed])
    // return {mint, isWhiteListed}
    return { mint };
};
