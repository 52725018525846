export const NetworkContextName = 'NETWORK'

export const connectorLocalStorageKey = "connectorId";

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect'
}

export interface Address {
  97?: string
  // 56: string
}
