import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "../src/App.css";
import "../src/assets/css/swiper-bundle.min.css";

import Web3ReactManager from "component/Web3ReactManager";
import Application from "views";


function App() {
  return (
    <div>
      <Web3ReactManager>
        <Application />
      </Web3ReactManager>
    </div>
  );
}

export default App;
