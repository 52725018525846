import { Web3Provider } from "@ethersproject/providers";
import { Signer } from '@ethersproject/abstract-signer';
import { Contract } from '@ethersproject/contracts';
import { useMemo } from "react";
import { useActiveWeb3React } from "./web3";
import { abi, contractAddress } from "config/contract";



export const useSigner = () => {
    const { library, account } = useActiveWeb3React();
    const signer = useMemo(
        () => (account ? library?.getSigner(account) : library),
        [library, account]
    );
    return signer ?? library;
};

export const getERC721Contract = (signerOrProvider?: Web3Provider | Signer) => {
    return new Contract(contractAddress, abi, signerOrProvider)
}

export const use721Contract = () => {
    const signer = useSigner();
    const getERC721Contract = (signerOrProvider?: Web3Provider | Signer) => {
        return new Contract(contractAddress, abi, signerOrProvider)
    }
    return useMemo(() => getERC721Contract(signer), [signer]);
}