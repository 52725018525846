import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { IWalletConnectProviderOptions } from "@walletconnect/types";
// import { BscConnector } from '@binance-chain/bsc-connector'
import { ConnectorNames } from "config/misc";
import { NetworkConnector } from ".";
import { Network } from "@web3-react/network";
import getNodeUrl from "utils/getRpcUrl";

const POLLING_INTERVAL = 12000;
let rpcUrl = getNodeUrl();
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
// const chainId =parseInt(process.env.REACT_APP_CHAIN_ID_LOCALHOST,10)

export const injected = new InjectedConnector({ supportedChainIds: [chainId] });
// export const injected = new InjectedConnector({ supportedChainIds: [chainId] })

const walletConnectorSetting: IWalletConnectProviderOptions = {
  rpc: { [chainId]: rpcUrl },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
};
export const walletconnect = new WalletConnectConnector(walletConnectorSetting);

// export const bscConnector = new BscConnector({ supportedChainIds: [chainId] })
// console.log('chainId',chainId,rpcUrl)
export const network = new NetworkConnector({
  urls: { [chainId]: rpcUrl },
});

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  // [ConnectorNames.BSC]: bscConnector,
};