/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "assets/images/logo.png";
import ConnectIcon from "assets/svg/connect";
import DiscordIcon from "assets/svg/discord";
import { ConnectorNames } from "config/misc";
import useAuth from "hooks/useAuth";
import { useActiveWeb3React } from "hooks/web3";

const Header: React.FC = () => {
  const { connect } = useAuth();
  const { account } = useActiveWeb3React();

  let displayName = "";
  if (account) {
    displayName =
      account.slice(0, 5) +
      "......" +
      account.slice(account.length - 4, account.length);
  }

  const handleConnect = () => {
    connect(ConnectorNames.Injected);
  };

  return (
    <div>
      <header className="header-section header--fixed animated fadeInDown">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">

              {/*  <a href="index.html">*/}
              {/*    <img src={logo} alt="logo" style={{ width: "200px" }} />*/}
              {/*  </a>*/}
              </div>
              <div className="menu-area">

                <div className="header-btn">

                  <button className="default-btn" onClick={handleConnect}>
                    <span>
                      {account ? (
                        displayName
                      ) : (
                        <>
                          Connect <ConnectIcon />
                        </>
                      )}
                    </span>
                  </button>
                </div>
                <div className="header-bar d-lg-none">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
