import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
// eslint-disable-next-line import/no-unresolved
// import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { connectorsByName } from 'component/connectors/web3Connectors'
import { ConnectorNames, connectorLocalStorageKey } from 'config/misc'
import { switchOrAddNetwork } from 'utils/ethereumRequest'
// import { useToast } from './useToast'
// import { toastTypes } from 'components/Toast'

const useAuth = () => {
    const { activate, deactivate } = useWeb3React()

    const connect = useCallback((connectorID: ConnectorNames) => {
        const connector = connectorsByName[connectorID]
        if (connector) {
            activate(connector, async (error: Error) => {
                if (error instanceof UnsupportedChainIdError) {
                    switchOrAddNetwork()
                } else {
                    window.localStorage.removeItem(connectorLocalStorageKey)
                    if (error instanceof NoEthereumProviderError) {
                        // toast(toastTypes.error, 'Provider Error', 'No provider was found')
                    } else if (
                        error instanceof UserRejectedRequestErrorInjected ||
                        error instanceof UserRejectedRequestErrorWalletConnect
                    ) {
                        if (connector instanceof WalletConnectConnector) {
                            const walletConnector = connector as WalletConnectConnector
                            walletConnector.walletConnectProvider = null
                        }
                        // toast(toastTypes.error, "Authorization error", error.message)
                    } else {
                        // toast(toastTypes.error, error.name, error.message)
                    }
                }
            })
        } else {
            // toast(toastTypes.error, "Can't find connector", 'The connector config is wrong')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { connect, discconect: deactivate }
}

export default useAuth
